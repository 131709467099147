module.exports = [
  {
    request: {
      urlPath: "tandr/inquiry",
      body: {
        vehicle: {
          vin: "3C4NJDAB8LT125491"
        }
      }
    },
    response: {
      body: {
        vehicle: {
          vehicleID: 18379519,
          vin: "3C4NJDAB8LT125491",
          vinLast8: "LT125491",
          vinVerifiedInd: true,
          modelYear: 2020,
          makeCode: "JEEP",
          modelCode: "CMP",
          bodyTypeCode: "4W",
          majorColorCode: "I",
          fuelTypeCode: "1",
          vehicleTypeCode: "A",
          grossVehicleWeight: 5000,
          newUsedCode: "N",
          lastUpdateDate: "2020-06-11T16:27:08-04:00",
          lastUpdateUserID: 69,
          titleBrands: [
            {
              titleBrandID: "1",
              lastUpdateDate: "0001-01-01T00:00:00Z"
            }
          ],
          stops: null,
          overrideStops: false,
          vehicleDetailID: 26915455,
          vehicleUseCode: "P",
          odometerID: 12683050,
          odometerTypeCode: "0",
          odometerReading: 10,
          odometerReadDate: "2020-06-11T00:00:00-04:00",
          titleID: 37721041
        },
        title: {
          titleID: 37721041,
          vehicleID: 18379519,
          ownershipID: 46892692,
          controlNo: "",
          outStateTaxCredit: 0,
          outStateTaxState: "",
          dealerCollectedTax: 0,
          tradeAllowance: 0,
          titleReIssueReason: "",
          taxExemptCode: 0,
          titleIssueDate: "2020-06-11T00:00:00-04:00",
          statusID: 1,
          statusDate: "2020-06-11T00:00:00-04:00",
          formerTNTitleID: 37721038,
          formerTitleNo: "10000007116",
          formerTitleState: "TN",
          surrenderStates: "",
          fairMarketValue: 0,
          stateItemTypeID: 120,
          lastUpdateDate: "2020-06-11T16:27:08-04:00",
          titleNo: "10000008014"
        },
        transaction: {
          transactionTimestamp: "2020-06-12T16:55:43-04:00",
          statusCode: "1",
          countyID: 10,
          transactionType: "CT",
          isVerify: false,
          fees: [
            {
              feeID: 7,
              feeName: "Title Fee",
              feeDescription: null,
              feeAmount: 5.5,
              feeRate: 5.5,
              isStateFee: false,
              isOptional: true,
              isEditable: false,
              isDefaultZero: false,
              feeGroup: "Title",
              subcategory: "Title",
              feeTaxDetail: null,
              amountOptions: [5.5, 0],
              defaultAmount: 5.5
            },
            {
              feeID: 9,
              feeName: "Issue Fee - Title",
              feeDescription: null,
              feeAmount: 5.5,
              feeRate: 5.5,
              isStateFee: false,
              isOptional: true,
              isEditable: true,
              isDefaultZero: false,
              feeGroup: "Title",
              subcategory: "Issuance",
              feeTaxDetail: null,
              defaultAmount: "5.50"
            },
            {
              feeID: 2932,
              feeName: "Archive Fee",
              feeDescription: null,
              feeAmount: 5,
              feeRate: 5,
              isStateFee: false,
              isOptional: true,
              isEditable: false,
              isDefaultZero: false,
              feeGroup: "Clerk",
              subcategory: "Clerk",
              feeTaxDetail: null,
              amountOptions: [5, 0],
              defaultAmount: 5
            },
            {
              feeID: 2548,
              feeName: "Notary Fee",
              feeDescription: null,
              feeAmount: 0,
              feeRate: 2,
              isStateFee: false,
              isOptional: true,
              isEditable: false,
              isDefaultZero: false,
              feeGroup: "Other",
              subcategory: null,
              feeTaxDetail: null,
              amountOptions: [0, 2],
              defaultAmount: 0
            },
            {
              feeID: 2566,
              feeName: "Copy Fee",
              feeDescription: null,
              feeAmount: 0,
              feeRate: 2,
              isStateFee: false,
              isOptional: true,
              isEditable: false,
              isDefaultZero: false,
              feeGroup: "Other",
              subcategory: null,
              feeTaxDetail: null,
              amountOptions: [0, 2],
              defaultAmount: 0
            },
            {
              feeID: 2565,
              feeName: "Certified Copy Fee",
              feeDescription: null,
              feeAmount: 0,
              feeRate: 2,
              isStateFee: false,
              isOptional: true,
              isEditable: false,
              isDefaultZero: false,
              feeGroup: "Other",
              subcategory: null,
              feeTaxDetail: null,
              amountOptions: [0, 2],
              defaultAmount: 0
            },
            {
              feeID: 212,
              feeName: "Misc Fee",
              feeDescription: null,
              feeAmount: 0,
              feeRate: 0,
              isStateFee: false,
              isOptional: true,
              isEditable: true,
              isDefaultZero: false,
              feeGroup: "Other",
              subcategory: null,
              feeTaxDetail: null,
              defaultAmount: "0.00"
            },
            {
              feeID: 8,
              feeName: "Mail Fee",
              feeDescription: null,
              feeAmount: 0,
              feeRate: 2,
              isStateFee: false,
              isOptional: true,
              isEditable: true,
              isDefaultZero: true,
              feeGroup: "Other",
              subcategory: "Postage",
              feeTaxDetail: null,
              defaultAmount: "0.00"
            }
          ],
          isCorrective: true,
          invoiceNo: 20163
        },
        owners: [
          {
            customerID: 0,
            customerType: "I",
            mailingAddressID: 32382127,
            physicalAddressID: 0,
            customerName: null,
            customerEMail: "",
            customerPhone: "",
            customerPhoneExt: "",
            customerStatusCode: "A",
            customerStatusDate: "2020-06-11T00:00:00-04:00",
            contactName: null,
            contactPhone: null,
            contactPhoneExt: null,
            emailRenewalInd: 0,
            massMailInd: false,
            deafInd: 2,
            checkReturnCount: null,
            individualCustomer: {
              customerID: 77646658,
              namePrefix: "",
              firstName: "TESTTETS",
              middleName: "",
              lastName: "YEETUS",
              nameSuffix: "",
              driverLicenseNo: null,
              driverLicenseState: null,
              ssn: null,
              birthDate: null
            },
            mailingAddress: {
              addressID: 0,
              nCOAAddressInd: false,
              pOVerifiedInd: false,
              addressTypeCode: "I",
              streetNo: null,
              address1: " 165 Clover Branch Rd, Elizabethton TN",
              city: "Elizabethton",
              state: "TN",
              postalCode: "37643",
              zIP5: "37643",
              countryCode: ""
            },
            physicalAddress: {
              addressID: 0,
              nCOAAddressInd: false,
              pOVerifiedInd: false,
              addressTypeCode: "I",
              streetNo: null,
              address1: "801 E Elk Ave, Elizabethton TN",
              city: "ELIZABETHTON",
              state: "TN",
              postalCode: "37643",
              zIP5: "37643",
              countyID: 10,
              countryCode: ""
            },
            ownershipRightCode: "O"
          }
        ],
        liens: [
          {
            lienID: 7949749,
            vehicleID: 18379519,
            lienHolderID: 77645086,
            lienDate: "2020-06-10T00:00:00-04:00",
            lienPrecedenceNo: 1,
            receivedDate: "2020-06-11T00:00:00-04:00",
            lastUpdateDate: "2020-06-11T16:27:08-04:00",
            lastUpdateUserID: 69,
            lienholder: {
              customerID: 0,
              customerType: "B",
              mailingAddressID: null,
              physicalAddressID: 0,
              customerName: "BONK OF YEETUS",
              customerEMail: null,
              customerPhone: null,
              customerPhoneExt: null,
              customerStatusCode: "A",
              customerStatusDate: "2020-04-07T00:00:00-04:00",
              contactName: null,
              contactPhone: null,
              contactPhoneExt: null,
              emailRenewalInd: 0,
              massMailInd: null,
              deafInd: 0,
              checkReturnCount: null,
              businessCustomer: {
                customerID: 77645086,
                faxNumber: null,
                fEIN: null,
                fleetInd: null,
                businessLicenseNo: null,
                businessName: "BONK OF YEETUS",
                altBusinessName: null,
                rebuilderInd: null,
                salesTaxAccountNo: null,
                suretyBondInd: null,
                licenseStatusCode: null,
                licenseStatusDate: "2020-06-12T00:00:00-04:00",
                licenseTypeCode: null,
                accountType: null,
                garageKeeperTowingInd: null,
                dealerInd: null
              },
              physicalAddress: {
                addressID: 0,
                nCOAAddressInd: false,
                pOVerifiedInd: false,
                addressTypeCode: "I",
                streetNo: null,
                address1: "331 INDUSTRIAL PARK RD, PINEY FLATS TN",
                city: "Piney Flats",
                state: "TN",
                postalCode: "37686",
                zIP5: "37686",
                countyID: 10,
                countryCode: ""
              }
            }
          }
        ],
        ownership: {
          ownershipID: 46892692,
          vehicleID: 18379519,
          purchaseDate: "2020-06-10T00:00:00-04:00",
          conjunctionCode: "0",
          statusCode: "A",
          statusDate: "2020-06-11T00:00:00-04:00"
        },
        comments: [],
        decal: null,
        undercoverTransaction: null,
        overrideReasonCode: null,
        overrideReason: "",
        feeSum: null,
        currentAddress: null,
        currentAddressFeeSum: null,
        starComments: null,
        username: "",
        payments: [
          {
            amount: 16,
            tenderType: "Cash"
          }
        ]
      }
    }
  }
];
