<template>
  <div>
    <div style="margin: 2%">
      <section class="hero is-light">
        <div class="hero-body">
          <br />
          <div class="field">
            <h2>
              Click
              <router-link :to="{ name: 'DevConfiguration' }" class="link"
                >here</router-link
              >to change CC device
            </h2>
            <p class="control" v-if="ccDevice == 'uniterm'">
              <button
                class="button is-danger"
                @click="testMonetra('DEVICEONLY')"
              >
                Test Ingenico Device Swipe / Chip
              </button>
              <button
                class="button is-danger"
                @click="testMonetra('DEVICEONLY|KEY')"
              >
                Test Ingenico User Manual Input
              </button>
              <button
                class="button is-danger"
                @click="testMonetra('GUIONLY|KEY')"
              >
                Test Ingenico Clerk Manual Input
              </button>
            </p>
            <p class="control" v-if="ccDevice == 'hid'">
              <button class="button is-danger" @click="hidSwipeTest()">
                test hid swipe
              </button>
            </p>
            <p>
              <button @click="webUSBCharge">Web HID Charge</button>
            </p>
          </div>

          <br />
          <div>
            Items to Print: {{ itemsToPrint }}
            <br />
            <div>
              <button @click="addPrintItem('decal')" class="plus">
                <span>+</span>
              </button>
              <button @click="removePrintItem('decal')" class="minus">
                <span>-</span>
              </button>
              <span for="jack">decal</span>
            </div>

            <div>
              <button @click="addPrintItem('top')" class="plus">
                <span>+</span>
              </button>
              <button @click="removePrintItem('top')" class="minus">
                <span>-</span>
              </button>
              <span for="john">top</span>
            </div>

            <div>
              <button @click="addPrintItem('201')" class="plus">
                <span>+</span>
              </button>
              <button @click="removePrintItem('201')" class="minus">
                <span>-</span>
              </button>
              <span for="mike">201</span>
            </div>

            <div>
              <button @click="addPrintItem('title')" class="plus">
                <span>+</span>
              </button>
              <button @click="removePrintItem('title')" class="minus">
                <span>-</span>
              </button>
              <span for="bravo">Title</span>
            </div>

            <div>
              <button @click="addPrintItem('tdrSticker')" class="plus">
                <span>+</span>
              </button>
              <button @click="removePrintItem('tdrSticker')" class="minus">
                <span>-</span>
              </button>
              <span for="bravo">TDRSticker</span>
            </div>
            <div>
              <button @click="addPrintItem('dealerPlate')" class="plus">
                <span>+</span>
              </button>
              <button @click="removePrintItem('dealerPlate')" class="minus">
                <span>-</span>
              </button>
              <span for="bravo">DealerPlate</span>
            </div>
            <label for="chkBox">Use Custom Print Data</label>
            <input id="chkBox" type="checkbox" v-model="useCustomTransObj" />

            <br />
            <button class="button is-danger" @click="printControllerTest('TN')">
              Show print page TN
            </button>
            <button class="button is-danger" @click="printControllerTest('MN')">
              Show print page MN
            </button>
            <button
              class="button is-danger"
              @click="printControllerTest('Controller')"
            >
              Test Print Controller
            </button>
          </div>
          <br />
          <div>
            <v-btn @click="batchPrintTest"> test Batch Print </v-btn>
            <br />
          </div>
          <br />
          <div>
            <button class="button is-danger" @click="addToVinHistory">
              Add to vinHistory
            </button>
            <br />
          </div>
          <div>
            <button class="button is-danger" @click="loaderTest">
              Test Load Screen
            </button>
          </div>
          <br />
          <div>
            <button class="button is-danger" @click="testPdfPrint()">
              test Title pdf Print
            </button>
          </div>
          <br />
          <div>
            <v-text-field label="username" v-model="apiuser" />
            <v-text-field label="password" v-model="apipass" />
            <v-btn @click="testMonCreds">Test Mon-creds</v-btn>
          </div>
          <div>
            <v-text-field label="log type" v-model="logType" />
            <v-text-field label="log title" v-model="logTitle" />
            <v-text-field label="log body" v-model="logBody" />
            <v-btn @click="testHubLogger">Test hub logger</v-btn>
          </div>
          <br />
          <div>
            <v-text-field label="vin to force ncic" v-model="vinToForce" />
            <v-btn @click="forceNcic">force ncic run</v-btn>
          </div>
          <br />
          <div>
            <v-btn @click="$api.logout()">kill Api Token</v-btn>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import transactionMixin from "@/mixins/transaction.mixin.js";

// import CryptoJS from 'crypto-js'
import { mapActions } from "vuex";
export default {
  name: "devTests",
  mixins: [transactionMixin],
  data() {
    return {
      localStorage: window.localStorage,
      vinToForce: "",
      logType: "error",
      logBody: "",
      logTitle: "TEST LOG",
      apiuser: "",
      apipass: "",
      DWObject: {},
      checked: false,
      apiURL: this.$store.getters.apiUrl,
      printers: "",
      selectedPrinter: "",
      bin: "",
      settingsObj: {},
      itemsToPrint: [],
      testImg: [],
      useDefaultUI: true,
      encodedTiff: "",
      options: {
        includeUI: {
          loadImage: {
            path: "",
            name: "tempImage"
          },
          initMenu: "filter",
          menuBarPosition: "left"
        },
        cssMaxWidth: 1000,
        cssMaxHeight: 1000
      },
      useCustomTransObj: false,
      ccDevice: ""
    };
  },
  mounted() {
    try {
      this.$hubapp.getHubConfigs().then(msg => {
        this.ccDevice = msg.ccDevice;
      });
    } catch (error) {
      this.$store.dispatch("setGlobalAlertState", {
        title: "Error",
        description: "There was a problem retrieving saved device settings.",
        icon: "error"
      });
    }
  },
  methods: {
    async testHubLogger() {
      try {
        this.$hubapp
          .log(this.logType, this.logTitle + "\n" + this.logBody)
          .then(() => {
            this.$root.$emit("push-alert", "log succeeded", { color: "green" });
          });
      } catch (error) {
      this.$root.$emit("push-alert", "log failed", {
          color: "red"
        });
      }
    },
    async testMonCreds() {
      const dataToSend = {
        username: this.apiuser,
        password: this.apipass,
        officeID: 10,
        countyID: 10
      };
      const ret = await this.$api.monetraUser(dataToSend);
      console.log(ret);
    },
    ...mapActions(["updateUser", "updateCardReturn"]),
    addToVinHistory() {
      this.$store.commit("vinHistoryArrayADD", {
        vehicle: { vin: "testtesttest" },
        transaction: { transactionTimestamp: Date.now() }
      });
    },
    imageScannerSubmitClicked() {
      console.log(this.encodedTiff);
      console.log(this.encodedTiff);
    },
    addPrintItem(option) {
      let found = false;
      this.itemsToPrint.forEach((doc, index) => {
        if (doc.doc == option) {
          this.itemsToPrint[index].copies++;
          found = true;
        }
      });

      if (!found) {
        this.itemsToPrint.push({ doc: option, copies: 1 });
      }
    },
    removePrintItem(option) {
      this.itemsToPrint.forEach((doc, index) => {
        if (doc.doc == option) {
          console.log(doc.doc);
          this.itemsToPrint[index].copies--;
        }

        if (this.itemsToPrint[index].copies == 0) {
          this.itemsToPrint.splice(index, 1);
        }
      });
    },
    hidSwipeTest() {
      try {
        this.$hubapp
          .charge({
            data: { amount: "5.00", type: "" }
          })
          .then(msg => {
            this.$store.dispatch("setGlobalAlertState", {
              title: "It worked!",
              description: JSON.stringify(msg)
            });
          });
      } catch (error) {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Failed!",
          description: "Did not connect",
          icon: "error"
        });
      }
    },
    buf2hex(buffer) {
      // buffer is an ArrayBuffer
      return Array.prototype.map.call(new Uint8Array(buffer), x =>
        ("00" + x.toString(16)).slice(-2)
      );
    },
    async webUSBCharge() {
      if (navigator.hid) {
        let devices = "";
        let device = "";
        devices = await navigator.hid.getDevices();
        if (devices.length > 0) {
          for (let i = 0; i < devices.length; i++) {
            if (
              devices[i].vendorId == "2765" &&
              devices[i].productName.indexOf("Magstripe") != -1
            ) {
              device = devices[i];
            }
          }
        }
        if (device == "") {
          device = await navigator.hid.requestDevice({ filters: [] });
        }
        if (!device.opened) {
          device.open();
        }
        device.addEventListener("inputreport", event => {
          this.decodeCard(this.buf2hex(event.data.buffer));
          device.close();
        });
      } else {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Failed!",
          description: "Not supported on this browser",
          icon: "error"
        });
      }
    },
    async decodeCard(hexArr) {
      let hexString = hexArr.join("");
      hexString = hexString.trim();
      let total_data_length =
        parseInt("0x" + hexArr[5], 16) +
        parseInt("0x" + hexArr[6], 16) +
        parseInt("0x" + hexArr[7], 16);
      //as per doc, this is rounding to the nearest multiple of 8.
      while (total_data_length % 8 != 0) {
        total_data_length++;
      }
      //spots 1 and 2 represent low and high bytes, add them together and parse as decimal.
      //add 6 because the delimiter is 3 chars long and we multiply by 2 for hex pair conversion.
      //multiply that number by 2 because decimal has one char per hex pair, then subtract the name and info data (26 chars)
      //this gives the beginning location of the e_id
      const firstNum = (parseInt(hexArr[2] + hexArr[1], 16) + 6) * 2 - 26;
      //get the e_id by starting at the location from above, we know from docs that e_id is always 20 chars
      const e_id = hexString.substring(firstNum, firstNum + 20);
      //trackdata is everything between hashs and the total data length. length is multiplied by 2 because each char represents a hex pair
      const index = hexString.split("3f2a").length - 1;
      const splitString = hexString.split("3f2a");
      const e_trackdata = splitString[index].substring(
        0,
        total_data_length * 2
      );

      const hmacObj = await this.getHmacValues();

      // let sendData = {
      //     username: hmacObj.user,
      //     monetra_req_timestamp: hmacObj.ts,
      //     monetra_req_sequence: hmacObj.seq,
      //     monetra_req_hmacsha256: hmacObj.hmacsha256,
      //     action:"admin",
      //     admin:"cardshieldticket",
      //     e_trackdata: e_trackdata,
      //     e_id: e_id,
      //     amount:'4.67'
      // };

      const sendData =
        '<?xml version="1.0"?>' +
        "<MonetraTrans>" +
        '<Trans identifier="1">' +
        "<username>" +
        hmacObj.user +
        "</username>" +
        "<monetra_req_timestamp>" +
        hmacObj.ts +
        "</monetra_req_timestamp>" +
        "<monetra_req_sequence>" +
        hmacObj.seq +
        "</monetra_req_sequence>" +
        "<monetra_req_hmacsha256>" +
        hmacObj.hmacsha256 +
        "</monetra_req_hmacsha256>" +
        "<amount>" +
        "4.67" +
        "</amount>" +
        "<action>admin</action>" +
        "<admin>cardshieldticket</admin>" +
        "<e_trackdata>" +
        e_trackdata +
        "</e_trackdata>" +
        "<e_id>" +
        e_id +
        "</e_id>" +
        "</Trans>" +
        "</MonetraTrans>";
      try {
        const response = await fetch("https://cctest.bisonline.com", {
          type: "POST",
          data: sendData,
          dataType: "xml",
          crossDomain: true
        });
        await response.json();
        this.$store.dispatch("setGlobalAlertState", {
          title: "It worked!",
          description: JSON.stringify(response.msg),
          icon: "error"
        });
      } catch (e) {
        this.$store.dispatch("setGlobalAlertState", {
          title: "Failed!",
          description: "Did not connect",
          icon: "error"
        });
      }
    },
    async forceNcic() {
      const response = await fetch(
        this.apiURL + "/vingenerator/ncic/" + this.vinToForce,
        {
          method: "GET",
          headers: {
            Authorization: "bearer " + this.$store.getters.apiToken,
            "Content-Type": "application/json"
          }
        }
      );
      if (this.handleErrors(response)) {
        return response;
      }
    },
    async getHmacValues() {
      const response = await fetch(
        this.apiURL + "/hmac?domain=" + "https://test3.bisonline.com/",
        {
          method: "GET",
          headers: {
            Authorization: "bearer " + this.$store.getters.apiToken,
            "Content-Type": "application/json"
          }
        }
      );
      if (this.handleErrors(response)) {
        return response;
      }
    },
    async testPdfPrint() {
      const response = await fetch(this.apiURL + "/pdfbytes/doc", {
        method: "GET",
        dataType: "json",
        async: false,
        headers: {
          Authorization: "bearer " + this.$store.getters.apiToken
        }
      });
      const msg = await response.json();
      try {
        this.$hubapp.printBBase64PDF({
          data: {
            send: JSON.stringify({
              pdf: msg,
              test: false
            })
          }
        });
      } catch (error) {
        this.$root.$emit("setLoading", false);
        this.$store.dispatch("setGlobalAlertState", {
          title: "Error",
          description:
            "The STAR Hub App is not running. Please download it from the login page and start it.",
          icon: "error"
        });
        this.$router.push({ name: "Search" });
      }
      //print this title pdf
    },
    async batchPrintTest() {
      for (let i = 0; i < 5; i++) {
        console.log("testing batchprint: ", i);
        this.itemsToPrint.push({ doc: "decal", copies: 1, index: 0 });
        await this.printControllerTest("Controller");
      }
    },
    async printControllerTest(type) {
      let test = type;
      if (type == "Controller") {
        test = false;
      }

      const oldTransObj = this.$store.getters.transObj;
      const oldFuelTypes = this.$store.getters.fuelTypes;

      if (
        this.useCustomTransObj &&
        this.itemsToPrint[0].doc !== "dealerPlate"
      ) {
        const testData = require("@/assets/testData/testData.js");
        const testProcessObj = testData[0].response.body;
        this.$store.commit("transObj", testProcessObj);

        this.$store.commit("fuelTypes", {
          0: {
            FuelTypeCode: "0",
            FuelTypeDescription: "Unknown",
            HybridInd: 0
          },
          1: { FuelTypeCode: "1", FuelTypeDescription: "Gas", HybridInd: 0 },
          2: {
            FuelTypeCode: "2",
            FuelTypeDescription: "Diesel",
            HybridInd: 0
          },
          3: {
            FuelTypeCode: "3",
            FuelTypeDescription: "Electric",
            HybridInd: 0
          },
          4: {
            FuelTypeCode: "4",
            FuelTypeDescription: "Propane",
            HybridInd: 0
          },
          5: {
            FuelTypeCode: "5",
            FuelTypeDescription: "Kerosene",
            HybridInd: 0
          },
          6: {
            FuelTypeCode: "6",
            FuelTypeDescription: "Gasohol",
            HybridInd: 0
          },
          7: { FuelTypeCode: "7", FuelTypeDescription: "Flex", HybridInd: 0 },
          8: {
            FuelTypeCode: "8",
            FuelTypeDescription: "Hybrid",
            HybridInd: 1
          },
          9: {
            FuelTypeCode: "9",
            FuelTypeDescription: "Other",
            HybridInd: 0
          },
          10: {
            FuelTypeCode: "10",
            FuelTypeDescription: "Test",
            HybridInd: 0
          },
          11: {
            FuelTypeCode: "11",
            FuelTypeDescription: "SPENT PLUTONIUM",
            HybridInd: 0
          },
          12: {
            FuelTypeCode: "12",
            FuelTypeDescription: "PEANUT BUTTER",
            HybridInd: 1
          },
          53: {
            FuelTypeCode: "53",
            FuelTypeDescription: "Kerosene",
            HybridInd: 0
          },
          "11A": {
            FuelTypeCode: "11A",
            FuelTypeDescription: "Jelly",
            HybridInd: 0
          }
        });
      } else if (
        this.useCustomTransObj &&
        this.itemsToPrint[0].doc == "dealerPlate"
      ) {
        this.$store.commit("transObj", {
          dealerPlateTransactions: {
            transactionPlates: [
              {
                transaction: {
                  transactionTimestamp: "2020-05-04T11:30:38-04:00",
                  statusCode: "1",
                  countyID: 10,
                  invoiceNo: 20125,
                  fees: [
                    {
                      feeID: 2216,
                      feeName: "Initial Plate Fee",
                      feeDescription: null,
                      feeAmount: 26.5,
                      feeRate: 26.5,
                      isStateFee: false,
                      isOptional: false,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Registration",
                      subcategory: "Registration",
                      feeTaxDetail: null
                    },
                    {
                      feeID: 201,
                      feeName: "Issuance Fee",
                      feeDescription: null,
                      feeAmount: 2.5,
                      feeRate: 2.5,
                      isStateFee: false,
                      isOptional: false,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Registration",
                      subcategory: "Issuance",
                      feeTaxDetail: null
                    },
                    {
                      feeID: 2548,
                      feeName: "Notary Fee",
                      feeDescription: null,
                      feeAmount: 2,
                      feeRate: 2,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    },
                    {
                      feeID: 2566,
                      feeName: "Copy Fee",
                      feeDescription: null,
                      feeAmount: 2,
                      feeRate: 2,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    },
                    {
                      feeID: 2565,
                      feeName: "Certified Copy Fee",
                      feeDescription: null,
                      feeAmount: 2,
                      feeRate: 2,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    },
                    {
                      feeID: 212,
                      feeName: "Misc Fee",
                      feeDescription: null,
                      feeAmount: 0,
                      feeRate: 0,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    }
                  ]
                },
                plate: {
                  plateNo: "3Z70Z7",
                  plateClass: "1000",
                  PlateID: 0,
                  PlateClassID: 0,
                  plateIssueYear: 2006,
                  expiresDate: "2021-05-31T04:00:00Z"
                }
              },
              {
                transaction: {
                  transactionTimestamp: "2020-05-04T11:30:38-04:00",
                  statusCode: "1",
                  countyID: 10,
                  invoiceNo: 20125,
                  fees: [
                    {
                      feeID: 2216,
                      feeName: "Initial Plate Fee",
                      feeDescription: null,
                      feeAmount: 26.5,
                      feeRate: 26.5,
                      isStateFee: false,
                      isOptional: false,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Registration",
                      subcategory: "Registration",
                      feeTaxDetail: null
                    },
                    {
                      feeID: 201,
                      feeName: "Issuance Fee",
                      feeDescription: null,
                      feeAmount: 2.5,
                      feeRate: 2.5,
                      isStateFee: false,
                      isOptional: false,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Registration",
                      subcategory: "Issuance",
                      feeTaxDetail: null
                    },
                    {
                      feeID: 2548,
                      feeName: "Notary Fee",
                      feeDescription: null,
                      feeAmount: 2,
                      feeRate: 2,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    },
                    {
                      feeID: 2566,
                      feeName: "Copy Fee",
                      feeDescription: null,
                      feeAmount: 2,
                      feeRate: 2,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    },
                    {
                      feeID: 2565,
                      feeName: "Certified Copy Fee",
                      feeDescription: null,
                      feeAmount: 2,
                      feeRate: 2,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    },
                    {
                      feeID: 212,
                      feeName: "Misc Fee",
                      feeDescription: null,
                      feeAmount: 0,
                      feeRate: 0,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    }
                  ]
                },
                plate: {
                  plateNo: "3Z70Z8",
                  plateClass: "1000",
                  PlateID: 0,
                  PlateClassID: 0,
                  plateIssueYear: 2006,
                  expiresDate: "2021-05-31T04:00:00Z"
                }
              },
              {
                transaction: {
                  transactionTimestamp: "2020-05-04T11:30:38-04:00",
                  statusCode: "1",
                  countyID: 10,
                  invoiceNo: 20125,
                  fees: [
                    {
                      feeID: 2216,
                      feeName: "Initial Plate Fee",
                      feeDescription: null,
                      feeAmount: 26.5,
                      feeRate: 26.5,
                      isStateFee: false,
                      isOptional: false,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Registration",
                      subcategory: "Registration",
                      feeTaxDetail: null
                    },
                    {
                      feeID: 201,
                      feeName: "Issuance Fee",
                      feeDescription: null,
                      feeAmount: 2.5,
                      feeRate: 2.5,
                      isStateFee: false,
                      isOptional: false,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Registration",
                      subcategory: "Issuance",
                      feeTaxDetail: null
                    },
                    {
                      feeID: 2548,
                      feeName: "Notary Fee",
                      feeDescription: null,
                      feeAmount: 2,
                      feeRate: 2,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    },
                    {
                      feeID: 2566,
                      feeName: "Copy Fee",
                      feeDescription: null,
                      feeAmount: 2,
                      feeRate: 2,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    },
                    {
                      feeID: 2565,
                      feeName: "Certified Copy Fee",
                      feeDescription: null,
                      feeAmount: 2,
                      feeRate: 2,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    },
                    {
                      feeID: 212,
                      feeName: "Misc Fee",
                      feeDescription: null,
                      feeAmount: 0,
                      feeRate: 0,
                      isStateFee: false,
                      isOptional: true,
                      isEditable: false,
                      isDefaultZero: false,
                      feeGroup: "Other",
                      subcategory: null,
                      feeTaxDetail: null
                    }
                  ]
                },
                plate: {
                  plateNo: "3Z70Z9",
                  plateClass: "1000",
                  PlateID: 0,
                  PlateClassID: 0,
                  plateIssueYear: 2006,
                  expiresDate: "2021-05-31T04:00:00Z"
                }
              }
            ],
            dealer: {
              DealerID: 77645164,
              Name: "FRANKS DEALERSHIP FOR TESTING",
              DealerMVCNo: "8675309",
              Representative: "fdsa",
              physicalAddress: {
                AddressID: 0,
                StreetNo: "",
                Address1: "123 ESTS",
                City: "TESt",
                State: "TN",
                PostalCode: "37643"
              },
              mailingAddress: {
                AddressID: 0,
                StreetNo: "",
                Address1: "123 fdsafsadfadfa",
                City: "fdsafdsafdsa",
                State: "VR",
                PostalCode: "37643"
              }
            }
          },
          payments: [{ amount: 87, tenderType: "Cash" }]
        });
      }

      let sendData = {};
      if (this.itemsToPrint[0].doc == "dealerPlate") {
        sendData = {
          send: JSON.stringify({
            transaction: { revenueStickerNo: "123" },
            transObj: this.$store.getters.transObj,
            itemsToPrint: this.itemsToPrint,
            paymentObj: {},
            regFeesObj: {},
            editObj: this.$store.getters.editObj,
            counties: this.$store.getters.counties,
            fuel: this.$store.getters.fuelTypes,
            registrationObj: {},
            countyId: this.$store.getters.countyId,
            state: this.$store.getters.state,
            userObject: this.$store.getters.userObject,
            test: test
          })
        };
      } else {
        //console.log(JSON.parse(sendData.send))
        const processObj = JSON.parse(
          JSON.stringify(this.$store.getters.transObj)
        );
        processObj.payments = [{ amount: 87, tenderType: "Cash" }];

        this.setPrintVars(processObj);
        sendData = {
          transaction: { revenueStickerNo: "123" },
          transObj: this.$store.getters.transObj,
          itemsToPrint: this.itemsToPrint,
          paymentObj: this.$store.getters.paymentObj,
          regFeesObj: this.$store.getters.regFeesObj,
          editObj: this.$store.getters.editObj,
          counties: this.$store.getters.counties,
          fuel: this.$store.getters.fuelTypes,
          registrationObj: this.$store.getters.registrationObj,
          countyId: this.$store.getters.countyId,
          state: this.$store.getters.state,
          userObject: this.$store.getters.userObject,
          test: test
        };
      }

      console.log(JSON.parse(sendData.send));
      this.$store.commit("transObj", oldTransObj);
      this.$store.commit("fuelTypes", oldFuelTypes);
      try {
        this.$hubapp.print(sendData).then(() => {});
      } catch (error) {
        this.$root.$emit("setLoading", false);
        this.$store.dispatch("setGlobalAlertState", {
          title: "Error",
          description:
            "The STAR Hub App is not running. Please download it from the login page and start it.",
          icon: "error"
        });
      }
      this.itemsToPrint = [];
    },
    listPrinters() {},
    testPrint() {
      // let sendData = {
      //   printer: this.selectedPrinter,
      //   bin: this.bin,
      //   fileName: "RegistrationTemplate.pdf"
      // };
      //const electron = require('electron');
      //const BrowserWindow1 = electron.remote.BrowserWindow;
      // let win = new BrowserWindow1({show: false})
      // win.loadURL('https://github.com')
      // win.webContents.on('did-finish-load', () => {
      // Use default printing options
      //   win.webContents.print(true,false,this.selectedPrinter)
      //  })
      // .print(true,false, this.selectedPrinter)
    },
    testScan() {
      this.options.includeUI.loadImage.path = "";
      try {
        this.$hubapp
          .scan({
            data: {
              send: JSON.stringify({
                test: true
              })
            }
          })
          .then(msg => {
            this.testImg = msg.imgs;
          });
      } catch (error) {
        this.$root.$emit("setLoading", false);
        this.$store.dispatch("setGlobalAlertState", {
          title: "Error",
          description:
            "The STAR Hub App is not running. Please download it from the login page and start it.",
          icon: "error"
        });
      }
    },
    testScanOCRGoogle() {
      this.$root.$emit("setLoading", true);
    },
    testMonetra(device) {
      this.$hubapp
        .charge({
          data: { amount: "5.00", type: device, custNum: "42" }
        })
        .then(msg => {
          this.$store.dispatch("setGlobalAlertState", {
            title: "Connected",
            description: msg.msg
          });
        });
    },
    storeResponse(arg) {
      this.updateCardReturn(arg);
    },
    loaderTest() {
      this.$root.$emit("setLoading", true);
      setTimeout(() => {
        this.$root.$emit("setLoading", false);
      }, 4000);
    }
  }
};
</script>

<style scoped>
.is-horizontal-center {
  justify-content: center;
}

#showEye {
  cursor: default;
}

.plus {
  background-color: blue;
  width: 20px;
  height: 20px;
  padding: 0px;
  border-radius: 100%;
  color: white;
  cursor: pointer;
}

.minus {
  background-color: red;
  width: 20px;
  height: 20px;
  padding: 0px;
  border-radius: 100%;
  color: white;
  cursor: pointer;
}

.minus > span,
.plus > span {
  position: relative;
  top: -4px;
  let: -1px;
  font-size: 18px;
  font-weight: bold;
}

.minus:hover,
.plus:hover {
  box-shadow: 1px 1px 3px gray;
}

.link {
  color: blue !important;
  text-decoration: underline;
}
</style>
